import React from 'react';

function Description({jobMapDescription}){
    return(

         <div>
            <h2>Description:</h2>
            <p>{jobMapDescription}</p>
         </div>
    )
                                    
}

export default Description;